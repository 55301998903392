import {Model, useRepo} from 'pinia-orm';
import Property from './Property';
import Contract from './Contract';
import Meter from './Meter';

export default class Flat extends Model {
  static entity = 'flats';

  static fields() {
    return {
      id: Flat.attr(''),
      uuid: Flat.attr(''),
      flatnumber: Flat.string(''),
        objectnumber: Flat.attr(null),
        door: Flat.attr(null),
      squaremeter: Flat.number(0),
      street: Flat.string(''),
      housenumber: Flat.string(''),
        staircase: Flat.string(null),
      floor: Flat.string(''),
      floor_details: Flat.attr({}),
        block: Flat.string(null),
      postalcode: Flat.string(''),
      city: Flat.string(''),
      property_id: Flat.attr(''),
      property: Flat.belongsTo(Property, 'property_id'),
      meters: Flat.morphMany(Meter, 'meters_id', 'meters_type'),
      contracts: Flat.morphMany(Contract, 'manageables_id', 'manageables_type'),
      activeContracts: Flat.morphMany(
        Contract,
        'manageables_id',
        'manageables_type',
      ),
      latestContract: Flat.morphOne(
        Contract,
        'manageables_id',
        'manageables_type',
      ),
      utility_room: Flat.boolean(false),
        utility_room_name: Flat.string(null),
        meta: Flat.attr(null),
    };
  }

  get fullAddress() {
    return Flat.fullAddress(this);
  }

  static fullAddress(flat) {
    let address = `${flat.street} `;
    address += flat.housenumber || '';
    if (flat.staircase) {
      address += `/${flat.staircase}`;
    }
    if (flat.floor) {
      address += `/${flat.floor}`;
    }
    if (flat.door) {
      address += `/${flat.door}`;
    }
    address += `, ${flat.postalcode} `;
    address += flat.city;
    return address;
  }

  get tenantName() {
    return Flat.tenantName(this);
  }

  static tenantName(flat) {
    if (flat.utility_room) {
      return flat.utility_room_name != null
        ? flat.utility_room_name
        : 'Allgemeinraum';
    }
    if (flat.is_vacancy) {
      return 'Leerstand';
    }
    return flat.latestContract?.user?.name;
  }

  get is_vacancy() {
    return this.latestContract === null && this.utility_room === false;
  }

  get squareMeters() {
    return this.squaremeter;
  }

    static repo() {
        return useRepo(Flat);
    }
}
