import { configure, extend, localize, setInteractionMode } from 'vee-validate';
import de from 'vee-validate/dist/locale/de.json';
import {
  alpha_num,
  alpha_spaces,
  length,
  min,
  numeric,
  required,
} from 'vee-validate/dist/rules';

setInteractionMode('lazy');
configure({
  classes: {
    passed: 'has-success',
    failed: 'has-error',
  },
});
extend('required', required);
extend('alpha_num', alpha_num);
extend('numeric', numeric);
extend('length', length);
extend('alpha_spaces', alpha_spaces);
extend('min', min);
localize('de', {
  // messages: de.messages,
  // merge messages with some custom ones
  messages: {
    ...de.messages,
    length: '{_field_} muss genau {length} Zeichen lang sein.',
  },
  names: {
    meter_point_number: 'Zählpunktnummer',
  },
  fields: {
    meter_point_number: {
      alpha_num:
        '{_field_} darf nur alphanumerische Zeichen enthalten. Geben Sie die Zählpunktnummer ohne Punkte ein.',
    },
  },
});
