import {Model, useRepo} from 'pinia-orm';
import Meter from './Meter';

export default class EnergyCarrier extends Model {
  static entity = 'energyCarriers';

  static fields() {
    return {
      id: EnergyCarrier.attr(''),
      climatically_adjusted: EnergyCarrier.boolean(false),
      meter: EnergyCarrier.hasMany(Meter, 'energyCarrier_id'),
      name: EnergyCarrier.string(''),
      order: EnergyCarrier.number(0),
      unit: EnergyCarrier.string(''),
      has_room_analysis: EnergyCarrier.boolean(false),
      settings: EnergyCarrier.attr({}),
    };
  }

    static repo() {
        return useRepo(EnergyCarrier);
    }
}
