import User from '../models/User';
import {useAuthStore} from "@/stores/auth.js";

export function useAuthenticationGuard(router) {
    return async (to, from, next) => {
        // If the route is public, continue
        if (!to.matched.some((record) => record.meta.requiresAuth)) {
            next();
            return;
        }

        if (useAuthStore().isAuthenticated === false) {
            let token = null;
            if (Object.hasOwn(to.query, 'token')) {
                token = to.query.token;
                to.query.token = undefined;
            }
            try {
                await User.refreshMe(token);
                router.replace({path: to.path, query: to.query});
            } catch {
                next({path: '/', query: {redirect: to.fullPath}});
                return;
            }
        }

        // If none of the routes are group-protected, it's fine.
        if (!to.matched.some((record) => record.meta.requiresAuthGroup)) {
            next();
        }
        // If yes, check if the user's part of the auth group.
        if (
            to.matched.some((record) =>
                record.meta.requiresAuthGroup?.some(
                    (group) => group === User.me().group_id,
                ),
            )
        ) {
            next();
        } else {
            next({path: User.me().homeUrl});
        }
    };
}
