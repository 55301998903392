/*
 * ******************************************************
 *  * Copyright (C) 2018-2020 webcrab GmbH
 *  * This file is part of ista VDM 2.0.
 *  * Unauthorized copying of this file, via any medium is strictly prohibited
 *  * Proprietary and confidential
 *  * Written by <phillip@webcrab.at>
 *  ******************************************************
 */

import some from 'lodash-es/some';

export default {
  computed: {
    hideObjectNumber() {
      return !some(this.properties, 'objectnumber');
    },
  },
};
