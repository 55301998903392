import {Model, useRepo} from 'pinia-orm';
import User from './User';
import Flat from "@/models/Flat.js";
import Property from "@/models/Property.js";

export default class Contract extends Model {
  static entity = 'contracts';

  static fields() {
    return {
      id: Contract.attr(null),
        user_id: Contract.attr(null),
      user: Contract.belongsTo(User, 'user_id'),
        from: Contract.attr(null),
        to: Contract.attr(null),
        contractObject: Contract.morphTo([Flat, Property], 'manageables_id', 'manageables_type'),
        manageables_id: Contract.attr(null),
        manageables_type: Contract.attr(null),
    };
  }

  get fromDate() {
    return this.from == null ? null : new Date(Date.parse(this.from));
  }

  get toDate() {
    return this.to == null ? null : new Date(Date.parse(this.to));
  }

    static repo() {
        return useRepo(Contract);
    }
}
