import Axios from 'axios';
import axiosRetry, {isNetworkError} from 'axios-retry';

const instance = Axios.create({
  baseURL: '/api',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
});

axiosRetry(instance, {
  retryDelay: (retryCount) => retryCount * 800,
  retryCondition: (error) => {
    return isNetworkError(error);
  },
});

export default instance;
