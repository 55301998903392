<!--
  - /*******************************************************
  -  * Copyright (C) 2018-2020 webcrab GmbH
  -  * This file is part of ista VDM 2.0.
  -  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  * Proprietary and confidential
  -  * Written by <phillip@webcrab.at>
  -  *******************************************************/
  -->
<template>
    <div class="vue-dialog">
        <div class="vue-dialog-content">
            <div class="vue-dialog-content-title">
                Zustimmungserklärung laut DSG und DSGVO
            </div>
            <div>Ich erteile meine Einwilligung, dass meine persönlichen Daten, nämlich Name, Adresse, Messgerätestatus sowie monatliche Verbräuche (je nach Ausstattung der Liegenschaft
                Wärmeverbrauch,
                Kälteverbrauch, Kaltwasserverbrauch, Warmwasserverbrauch) zum Zweck der Visualisierung im VDM-Portal in Form von Nutzeranalysen, Verbrauchsanalyse bzw. Geräteübersicht von ista
                verarbeitet
                werden dürfen und diese für die zuständige Hausverwaltung oder den zuständigen Energieversorger bzw. deren Rechtsnachfolger zum Zweck der Information über das Portal einsehbar sind.
                Diese
                Einwilligung kann jederzeit unter dem Menüpunkt „Benutzerprofil“ auf der Startseite Ihres Accounts widerrufen werden.
                <br>
                Hiermit stimme ich außerdem den <a href="/terms-of-use" target="_blank">Nutzungsbedingungen</a> des VDM-Webportals zu.
            </div>
        </div>
        <div class="vue-dialog-buttons" v-if="buttons">
            <button
                v-for="(button, index) in buttons"
                :class="button.class || 'vue-dialog-button'"
                type="button"
                tabindex="0"
                :style="buttonStyle"
                :key="index"
                v-html="button.title"
                @click.stop="click(index, $event)"
            >{{ button.title }}
            </button>
        </div>
    </div>
</template>
<script>

import User from "@/models/User.js";

export default {
  name: 'DataAccessModal',
    data() {
        return {
            buttons: [
                {
                    title: 'Einwilligung erteilen',
                    class: 'btn btn-success',
                    handler: () => {
                        User.me().acceptDataAccess().then(() => {
                            this.$emit('close');
                        });
                    },
                },
                {
                    title: 'Einwilligung nicht erteilen',
                    class: 'btn btn-danger',
                    handler: () => {
                        this.$emit('close');
                    },
                },
            ],
        }
    },
  mounted() {
  },
  methods: {
      click(buttonIndex, event, source = 'click') {
          const button = this.buttons[buttonIndex]
          const handler = button?.handler

          if (typeof handler === 'function') {
              handler(buttonIndex, event, {source})
          }
      }
  },
    computed: {
        buttonStyle() {
            return {
                flex: `1 1 ${100 / this.buttons.length}%`
            }
        }
    },
};
</script>

