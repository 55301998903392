import {acceptHMRUpdate, defineStore} from 'pinia';
import User from '@/models/User.js';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    loggedInUserId: null,
  }),
  getters: {
    isAuthenticated: (state) => !!state.loggedInUserId,
    authenticatedUser: (state) => {
      return User.repo().find(state.loggedInUserId);
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
